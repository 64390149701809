import { render, staticRenderFns } from "./ComparativaComponent.vue?vue&type=template&id=1e561400&scoped=true&"
import script from "./ComparativaComponent.vue?vue&type=script&lang=js&"
export * from "./ComparativaComponent.vue?vue&type=script&lang=js&"
import style0 from "./ComparativaComponent.vue?vue&type=style&index=0&id=1e561400&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e561400",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCheckbox,VCol,VContainer,VRow,VSimpleTable})
